import type { CmsModel } from 'types/cms';
import { GET_COLLECTIONS, GET_COLLECTION_ITEMS, GET_COLLECTIONS_AND_ITEMS } from 'admin/constants/actions';
import type { GetCollectionsAction, GetCollectionItemsAction, GetCollectionsAndItemsAction } from 'admin/actions/cms';

type ReducerState = CmsModel;

type ReducerActions = GetCollectionsAction | GetCollectionItemsAction | GetCollectionsAndItemsAction;

const initialState: ReducerState = {
	collections: null,
	items: null,
};

export default function cmsReducer(state = initialState, { type, payload }: ReducerActions): ReducerState {
	switch (type) {
		case GET_COLLECTIONS.FULFILLED:
			return { ...state, collections: payload.result ?? null };

		case GET_COLLECTION_ITEMS.FULFILLED:
			if (payload.result) {
				return {
					...state,
					items: { ...state.items, [payload.result.collectionId]: payload.result.items },
				};
			}
			return state;

		case GET_COLLECTIONS_AND_ITEMS.FULFILLED:
			return { ...state, items: payload.result?.items ?? null, collections: payload.result?.collections ?? null };

		default:
			return state;
	}
}
