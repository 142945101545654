import t from 'utils/translate';
import { COMPONENT_TYPE as TYPE, SCORE, STORY_SETTING_PROP, STORY_SETTINGS_COMPONENTS } from 'common/constants';
import { fields } from 'admin/components/pages/Story/Flow/CreateCardModal/Form';
import { minMax } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/NumberField/helpers';

export const text = (path: string): string => t(`story.cardSettingsModal.${path}`);

export const cardSettingsPath = (cardId: string) =>
	`settings.${STORY_SETTINGS_COMPONENTS[TYPE.CARD]?.path({ cardId })}`;

export const { score, showCorrect, showResults, countType, answersMinMax, forceSubmitBtn } = STORY_SETTING_PROP;

export const names = {
	// General
	name: fields.name,
	[score]: (cardId: string) => `${cardSettingsPath(cardId)}.${score}`,
	[showCorrect]: (cardId: string) => `${cardSettingsPath(cardId)}.${showCorrect}`,
	[showResults]: (cardId: string) => `${cardSettingsPath(cardId)}.${showResults}`,
	_setScoreForAll: '_setScoreForAll',
	[countType]: (cardId: string) => `${cardSettingsPath(cardId)}.${countType}`,
	[answersMinMax]: (cardId: string) => `${cardSettingsPath(cardId)}.${answersMinMax}`,
	[forceSubmitBtn]: (cardId: string) => `${cardSettingsPath(cardId)}.${forceSubmitBtn}`,

	// SEO
	seoTitle: (cardId: string) => `${cardSettingsPath(cardId)}.seo.title`,
	seoDescription: (cardId: string) => `${cardSettingsPath(cardId)}.seo.description`,
	seoKeywords: (cardId: string) => `${cardSettingsPath(cardId)}.seo.keywords`,

	// Share
	shareTitle: (cardId: string) => `${cardSettingsPath(cardId)}.share.title`,
	shareDescription: (cardId: string) => `${cardSettingsPath(cardId)}.share.description`,
	shareMsg: (cardId: string) => `${cardSettingsPath(cardId)}.share.msg`,
	shareImage: (cardId: string) => `${cardSettingsPath(cardId)}.share.image`,
};

/* eslint-disable */
export enum VIEW {
	GENERAL,
	SHARE,
	SEO,
}
/* eslint-enable */

export const menu = [
	{ label: 'General', view: VIEW.GENERAL },
	{ label: 'Share', view: VIEW.SHARE },
	{ label: 'SEO', view: VIEW.SEO },
];

export const parseScore = (v: string) => {
	const int = parseInt(v, 10);
	return Number.isNaN(int)
		? '' // delete
		: minMax({
				value: int,
				min: SCORE.MIN,
				max: SCORE.MAX,
			});
};
